import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Input } from './Input';
import { Controller, useForm } from 'react-hook-form';
import Modal from './Modal';
import { validatePhonePrefix } from '../validators/validatePhonePrefix';
import { Collapse } from 'react-collapse';
import useOutsideAlerter from '../Hooks/useOutsideAlerter';
import moment from 'moment';
import { SuccessPage } from './SuccessPage';
import { getUserID, getUser } from '../reducers/user';
import { getActionID, getCurrentAction } from '../reducers/actions';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import Receipt from "../../img/receipt.svg"
import Camera from "../../img/camera.svg"
import BackArrow from "../../img/arrow-back.svg"
import Flash from "../../img/flash.svg"
import Accept from "../../img/accept.svg"
import Reject from "../../img/reject.svg"
import Done from "../../img/done.svg"

const Webform = () => {
  const API_URL_TEST = 'https://api-dev.webform-lt.tdy-apps.com';
  const API_URL_PROD = 'https://api-prod.webform-lt.tdy-apps.com';

  const API_URL = process.env.REACT_APP_API_URL === "https://api.tourplanner.lt" ? API_URL_PROD : API_URL_TEST;

  const { handleSubmit, register, errors, setValue, clearErrors, getValues, control, reset } = useForm({
    defaultValues: {
      brand: "Glo",
      numberPrefix: "+370"
      // model: "Hyper+ UNIQ",
    }
  });
  const [formValues, setFormValues] = useState({});
  const [smsCode, setSmsCode] = useState('');
  const [emailCode, setEmailCode] = useState('');
  const [popup, setPopup] = useState({ isOpen: false, type: 'smsPopup' });
  const [isLoading, setIsLoading] = useState(false);
  const [isOTPLoading, setIsOTPLoading] = useState(false)
  const [model, setModel] = useState('');
  const [brand, setBrand] = useState('Glo');
  const [isHyperCareConsent, setIsHyperCareConsent] = useState(true);
  const [requestError, setRequestError] = useState({ description: 'Błąd ogólny', code: '' });
  const [otpError, setOtpError] = useState()
  const [isMounted, setIsMounted] = useState(false);
  const [isConsentSectionCollapsed, setIsConsentSectionCollapsed] = useState(false);
  const [isDropdown, setIsDropdown] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);


  const [, forceUpdate] = React.useReducer(x => x + 1, 0); // for ugly fix

  const currentAction = useSelector(getCurrentAction);
  const userID = useSelector(getUserID);
  const user = useSelector(getUser)

  const videoRef = useRef(null);
  const photoRef = useRef(null);
  const stripRef = useRef(null);
  const [initCamera, setInitCamera] = useState(false)
  const [imgReady, setImgReady] = useState(false)
  const [finalImgURL, setFinalImgURL] = useState("")

  console.log(finalImgURL)

  useEffect(() => {
    if (initCamera) {
      getVideo();
      document.body.classList.add("disable-scroll")
      window.scrollTo(0, 0);
    }
    return () => {
      document.body.classList.remove("disable-scroll")
    }
  }, [videoRef, initCamera]);

  const getVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: false, video: { width: 1600, height: 1100, facingMode: "environment" } })
      .then(stream => {
        let video = videoRef.current;
        video.srcObject = stream;
        video.play();
      })
      .catch(err => {
        console.error("error:", err);
      });
  };

  const paintToCanvas = () => {
    let video = videoRef.current;
    let photo = photoRef.current;
    let ctx = photo.getContext("2d");

    const width = 1100;
    const height = 1600;
    photo.width = width;
    photo.height = height;

    return setInterval(() => {
      ctx.drawImage(video, 0, 0, width, height);
    }, 200);
  };

  const takePhoto = () => {
    let photo = photoRef.current;
    let strip = stripRef.current;

    console.warn(strip);

    const data = photo.toDataURL("image/jpeg");

    console.warn(data);
    const link = document.createElement("a");
    link.href = data;
    // link.setAttribute("download", "myWebcam");
    link.innerHTML = `<img src='${data}' alt='thumbnail'/>`;
    strip.replaceChild(link, strip.firstChild)
    // if (strip.childElementCount === 2) {
    //   strip.replaceChild(link, strip.firstChild)
    // } else {
    //   strip.insertBefore(link, strip.firstChild);
    // }

    // Čekis pridėtas
    setImgReady(true)
  };

  function DataURIToBlob(dataURI) {
    console.log(dataURI)
    const splitDataURI = dataURI?.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0]?.split(':')[1]?.split(';')[0] ?? ""

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
  }

  function createReciept(imgbase, beacon = "fake_beacon") {
    let userData = new FormData()
    let input = {
      "action": {
        "uuid": currentAction.uuid
      },
      "device": {
        serial: getValues().deviceName || "fake_serial",
        beacon: beacon
      }
    }

    userData.append('_input', JSON.stringify(input))
    userData.append('files[]', DataURIToBlob(imgbase))

    fetch(process.env.REACT_APP_API_URL + "/action/receipt/create", {
      method: "POST",
      headers: {
        "Authorization": "Bearer " + (localStorage.getItem("state") ? JSON.parse(localStorage.getItem("state"))?.user?.token?.uuid : "")
      },
      body: userData
    })
      .then((response) => {
        return response.json()
      })
      .then(
        (result) => {
          if (result.status?.success) {

          } else {

          }
        },
        (error) => {
          console.log(error)
        }
      )
  }

  const DEVICE = {
    GLO: 'GLO',
    VUSE: 'VUSE',
  };
  const [device, setDevice] = useState(DEVICE.GLO);

  const availableColorsGlo =
    getValues().model === "Hyper X2" ?
      [
        {
          value: "Black-Red",
          name: "RAUDONA JUODA"
        },
        {
          value: "White-Gold",
          name: "BALTAS AUKSINIS"
        },
        {
          value: "Green-Pine",
          name: "MĖTINĖ"
        },
        {
          value: "Blue",
          name: "MĖLYNA"
        },
        {
          value: "Orange",
          name: "ORANŽINĖ"
        },
        {
          value: "Black",
          name: "JUODA"
        },
        {
          value: "Khaki",
          name: "RUSVAI ŽALSVA"
        },
        {
          value: "Black-Limited",
          name: "BLACK LIMITED"
        },
        {
          value: "Blue-Limited",
          name: "MĖLYNAS LIMITED"
        }
      ] :
      getValues().model === "Hyper Pro" ?
        [
          {
            value: "Amber-Bronze",
            name: "AMBER BRONZE"
          },
          {
            value: "Jade-Teal",
            name: "JADE TEAL"
          },
          {
            value: "Lapis-Blue",
            name: "LAPIS BLUE"
          },
          {
            value: "Obsidian-Black",
            name: "OBSIDIAN BLACK"
          },
          {
            value: "Purple-Sapphire",
            name: "PURPLE SAPPHIRE"
          },
          {
            value: "Quartz-Rose",
            name: "QUARTZ ROSE"
          },
          {
            value: "Ruby-Black",
            name: "RUBY BLACK"
          },
        ] :
        [
          {
            value: "Rosey-Gold",
            name: "Rosey Gold"
          },
          {
            value: "Light-Teal",
            name: "Light Teal"
          },
          {
            value: "Crisp-Purple",
            name: "Crisp Purple"
          },
          {
            value: "Ocean-Blue",
            name: "Ocean Blue"
          },
          {
            value: "Velvet-Pink",
            name: "Velvet Pink"
          },
          {
            value: "Space-Navy",
            name: "Space Navy"
          },
          {
            value: "Moonless-Black",
            name: "Moonless Black"
          }
        ];

  const sampling = [
    {
      value: "Polar Mint 4mg",
      name: "Polar Mint 4mg",
      sku: "K92.103.0001"
    },
    {
      value: "Berry Frost 6mg",
      name: "Berry Frost 6mg",
      sku: "K92.103.0004"
    }
  ]

  const availableColors = [
    {
      value: "Black",
      name: "Black"
    },
    {
      value: "Grey",
      name: "Grey"
    },
    {
      value: "Rose",
      name: "Rose"
    },
    {
      value: "Gold",
      name: "Gold"
    },
    {
      value: "Silver",
      name: "Silver"
    },
    {
      value: "Champagne",
      name: "Champagne"
    },
    {
      value: "Blue",
      name: "Blue"
    },
    {
      value: "Pink",
      name: "Pink"
    },
    {
      value: "Graphite",
      name: "Graphite"
    }
  ]

  const categories = [
    // {
    //   value: 'Glo',
    //   name: 'GLO',
    // },
    // {
    //   value: 'iQOS',
    //   name: 'IQOS',
    // },
    {
      value: 'Traditional cigarettes',
      name: 'Tradicinės cigaretės',
    },
    {
      value: 'THP',
      name: 'Kaitinamasis tabakas',
    },
    {
      value: 'E-Cigarettes',
      name: 'el.cigaretės(vape\'as)',
    },
    {
      value: 'Oral',
      name: 'SNUS',
    },
    {
      value: 'Other',
      name: 'Kiti tabako gaminiai',
    }
  ]

  const cigs = [
    {
      value: 'Camel',
      name: 'Camel'
    },
    {
      value: 'Chesterfield',
      name: 'Chesterfield'
    },
    // {
    //   value: 'DAVIDOFF',
    //   name: 'DAVIDOFF'
    // },
    {
      value: 'L&M',
      name: 'L&M'
    },
    {
      value: 'L&D',
      name: 'L&D'
    },
    {
      value: 'Marlboro',
      name: 'Marlboro'
    },
    // {
    //   value: 'PALL MALL',
    //   name: 'PALL MALL'
    // },
    // {
    //   value: 'R1',
    //   name: 'R1'
    // },
    // {
    //   value: 'RGD',
    //   name: 'RGD'
    // },
    {
      value: 'Rothmans',
      name: 'Rothmans'
    },
    // {
    //   value: 'VOGUE',
    //   name: 'VOGUE'
    // },
    // {
    //   value: 'WEST',
    //   name: 'WEST'
    // },
    {
      value: 'Winston',
      name: 'Winston'
    },
    // {
    //   value: 'Other',
    //   name: 'Inne',
    // }
    {
      value: 'Kent',
      name: 'Kent'
    },
    {
      value: 'Parliament',
      name: 'Parliament'
    },
  ]

  const ecigs = [
    {
      value: 'Switch',
      name: 'Switch'
    },
    {
      value: 'Whoop',
      name: 'Whoop'
    },
    {
      value: 'Others',
      name: 'Kiti'
    },
    {
      value: 'Open systems',
      name: 'Atviros systemos(vipe\'as)'
    },
    // {
    //   value: 'Vype',
    //   name: 'Vype'
    // },
    // {
    //   value: 'Other',
    //   name: 'Inne',
    // }
  ]

  const thp = [
    {
      value: 'Heets',
      name: 'Heets'
    },
    {
      value: 'Neo',
      name: 'Neo'
    },
    {
      value: 'Others',
      name: 'Others'
    },
  ]

  const assetSources = [
    {
      value: 'Hostess',
      name: 'Hostess'
    },
    // {
    //   value: 'HST – oferta POP',
    //   name: 'HST – oferta POP'
    // },
    // {
    //   value: 'HST – paczki od HST',
    //   name: 'HST – paczki od HST',
    // },
    // {
    //   value: 'HST – sprzedaż HST',
    //   name: 'HST – sprzedaż HST',
    // },
    // {
    //   value: 'HST – urządzenie od HST',
    //   name: 'HST – urządzenie od HST',
    // }
  ]

  const numberPrefixes = [
    {
      name: "+48",
      value: "+48"
    },
    {
      name: "+370",
      value: "+370"
    },
    {
      name: "+371",
      value: "+371"
    },
  ]

  const bundles = [
    {
      name: 'None',
      value: 'None',
    },
    {
      name: 'Mint',
      value: 'Mint',
    },
    {
      name: 'Fruit',
      value: 'Fruit',
    },
    {
      name: 'Kent',
      value: 'Kent',
    }
  ]

  const [inputValue, setInputValue] = useState({
    firstName: '',
    lastName: '',
    emailAddress: '',
    mobileNumber: '',
    hostessCode: userID,
    tourPlan: currentAction?.ident,
    date: '',
    model: ' ',
    brand: 'Glo',
    assetSource: 'Hostess',
    deviceName: '',
    registerType: "",
    lit_preferred_voucher: "",
    numberPrefix: "+370"
  })

  const resetForm = () => {
    setInputValue({
      firstName: '',
      lastName: '',
      emailAddress: '',
      mobileNumber: '',
      hostessCode: userID,
      tourPlan: currentAction?.ident,
      date: '',
      model: '',
      brand: 'Glo',
      assetSource: 'Hostess',
      registerType: "",
      lit_preferred_voucher: "",
      numberPrefix: "+370"
    })
    reset();
    setSmsCode('')
    setEmailCode('')
    setTimeout(() => forceUpdate(), 300)
  }

  const handleChange = (e) => {
    setInputValue({
      ...inputValue,
      [e.target.name]: e.target.value
    });

    //console.log(inputValue)
  };


  const dropdownRef = useRef();

  useOutsideAlerter(dropdownRef, () => setIsDropdown(false));

  useEffect(() => {
    // console.log(errors);
    console.log(getValues())
  });

  const getBrandAndModel = (brand) => {
    switch (brand.toLowerCase()) {
      case 'glo':
        return {
          brand: 'Glo',
          model: 'Hyper',
        };
      case 'vuse':
        return {
          brand: 'Vuse',
          model: 'ePen',
        };
      // case 'velo':
      // 	return {
      // 		brand: 'Velo',
      // 		model: 'Velo',
      // 	};
    }
  };

  const sendEvent = (event, action, label, value = null) => {
    if (window.dataLayer) {
      window.dataLayer.push({
        'event': event,
        'eventCategory': "Form",
        'eventAction': action,
        'eventLabel': label,
        'eventValue': value
      });
      //console.log({
      // 'event' : event,
      // 'eventCategory' : "Form",
      // 'eventAction' : action,
      // 'eventLabel' : label,
      // 'eventValue': value
      // })
    }
  }

  const onSubmit = (values) => {
    //sendEvent("button", "click", "REJESTRACJA")
    //console.log(values);
    setIsLoading(true);
    setFormValues(values);
    verifyPhone(values['mobileNumber'], values['emailAddress']);
    setPopup({ isOpen: true, type: 'smsPopup' });
  };

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setIsConsentSectionCollapsed(true);
    } else {
      setIsConsentSectionCollapsed(false);
    }
  }, [errors]);

  useEffect(() => {
    setTimeout(() => {
      setIsMounted(true);
    }, 100);
  });

  const SMS_VERIFICATION_ENDPOINT = '/profile/otp';
  const REGISTER_ENDPOINT = '/profile/register';
  // const VALIDATE_SERIAL_NUMBER = '/device/validate';

  const CONSENTS = {
    ageVerified: true,
    personalDataRegistrationAuthorisation: true,
    newsletterCommercialAuthorization: true,
    mobileOptIn: true,
    socialMediaOptIn: true,
    hypercareOptIn: true,
    personalDataRegistrationHandling: true,
    marketResearchAuthorisation: true,
    externalCompaniesAuthorization: true,
  };

  const sendForm = () => {
    setIsOTPLoading(true)
    const values = getValues()
    //console.log(values);


    // let data = {
    //   process: 'TOURPLANNER',
    //   otp: smsCode,
    //   otpEmail: emailCode,
    //   firstName: values.firstName,
    //   lastName: values.lastName,
    //   emailAddress: values['emailAddress'],
    //   mobileNumber: '+48' + values['mobileNumber'],
    //   tourPlan: values.tourPlan,
    //   hostessCode: values['hostessCode'],
    //   accountSource: 'Hostess',
    //   assetSource: values.assetSource,
    //   emailVerificationStatus: "Verified",
    //   emailVerified: true,
    //   phoneVerified: true,
    //   ageVerified: true,
    //   formIdent: 'glo.pl-tourplanner',
    //   brand: values.brand,
    //   lastModifiedSource: 'Hostess',
    //   category: values.category,
    //   secondaryBrand: values.secondaryBrand,
    //   deviceName: values?.deviceName,
    //   assetItems: [],
    //   productInterestItems: [],
    //   consent: {
    //     ageVerified: true,
    //     externalCompaniesAuthorization: true,
    //     hypercareOptIn: values['checkbox-6'],
    //     marketResearchAuthorisation: true,
    //     mobileOptIn: true,
    //     newsletterCommercialAuthorization: true,
    //     personalDataRegistrationAuthorisation: true,
    //     personalDataRegistrationHandling: true,
    //     socialMediaOptIn: true,
    //   }
    // };
    let profile = { // previous data
      // "consumers": [

      // ],
      // "profile": {
      //   "cellphone": {
      //     "intCode": "37",
      //     "areaCode": "0",
      //     "number": getValues().mobileNumber
      //   },
      //   "address": {
      //     "countryId": 1125
      //   },
      //   "email": getValues().emailAddress,
      //   "lastname": getValues().firstName,
      //   "firstname": getValues().lastName,
      //   "policy_agreement": getValues()["checkbox-6"],
      //   "digital_data_agreement": getValues()["checkbox-2"],
      //   "validEmailState": true,
      //   "email.valueState": "VALID",
      //   "cellphone.valueState": "VALID",
      //   "consent_age": getValues()["checkbox-1"],
      //   "consent_hypercare": true,
      //   "consent_phone": getValues()["checkbox-3"],
      //   "consent_com": getValues()["checkbox-4"],
      //   "lead_source_lit": "Hostess",
      //   "brand_lit": "GLO",
      //   "thp_status": "Consumer",
      //   "device_counter": 1,
      //   "hostess_id_lit": getValues().hostessCode,
      //   "store_id_lit": "123467",
      //   "tourplanner_id_lit": getValues().tourPlan,
      //   "category_before_lit": getValues().category,
      //   "lit_cat_brand_before": getValues().secondaryBrand
      // },
      // "optIns": [

      // ],
      // "credential": {
      //   "principal": getValues().emailAddress,
      //   "password": null
      // }
      "profile": {
        "otp": smsCode,
        "cellphone": {
          "intCode": inputValue.numberPrefix.substring(1, 3),
          "areaCode": inputValue.numberPrefix.length === 4 ? inputValue.numberPrefix.at(3) : getValues().mobileNumber.at(0),
          "number": inputValue.numberPrefix.length === 4 ? getValues().mobileNumber : getValues().mobileNumber.substring(1)
        },
        "email": getValues().emailAddress,
        "lastname": getValues().firstName,
        "firstname": getValues().lastName,
        "policy_agreement": getValues()["checkbox-2"],
        "digital_data_agreement": false,
        // "validEmailState": false,
        // "email.valueState": "VALID",
        "cellphone.valueState": "VALID",
        "consent_age": getValues()["checkbox-1"],
        "consent_hypercare": getValues()["checkbox-6"],
        "consent_phone": getValues()["checkbox-4"],
        "consent_com": getValues()["checkbox-3"],
        "lead_source_lit": "Hostess",
        "brand_lit": "GLO",
        "thp_status": "Consumer",
        "device_counter": 1,
        "hostess_id_lit": getValues().hostessCode,
        "store_id_lit": "123467",
        "tourplanner_id_lit": getValues().tourPlan,
        "category_before_lit": getValues().category,
        "lit_cat_brand_before": getValues().secondaryBrand ?? "Other",
        "lit_promo_purchase": getValues().registerType === "device-register-promo" ? true : false,
        ...(getValues().registerType !== "device-register-promo" && { "lit_preferred_voucher": getValues().lit_preferred_voucher })
      },
    }

    let item = {
      "lit_myglo_number": getValues().deviceName,
      "lit_purchase_date": new Date().getTime(),
      "lit_brand": getValues().brand,
      "lit_model": getValues().model,
      "lit_device_colour": getValues().color,
      "lit_asset_source": "Hostess",
      "bundle": getValues().bundle
    }

    // let productInterestItems = [
    //   {
    //     "sku": sampling.find(s => s.name === values.sampling)?.sku,
    //     "status": "Trial",
    //     "unitOfMeasure": "Can",
    //     "name": values.sampling,
    //     tourPlan: values.tourPlan
    //   }
    // ]


    // let assetItems = [
    //   {
    //     brand: values.brand,
    //     colour: values.color,
    //     status: 'Active',
    //     purchaseDate: moment().format('YYYY-MM-DD'),
    //     model: values.model,
    //     type: 'Sold',
    //     tourPlan: values.tourPlan,
    //     assetSource: values?.assetSource,
    //     deviceName: values?.deviceName,
    //   }
    // ]

    // data = {
    //   lead: {
    //     ...data,
    //     assetItems: getValues().registerType != 'sampling' ? assetItems : [],
    //     productInterestItems: getValues().registerType != 'device-register' ? productInterestItems : []
    //   }
    // }

    // fetch(API_URL + REGISTER_ENDPOINT, {
    fetch(API_URL + "/profile/register", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(values.registerType === "device-register" || values.registerType === "device-register-promo" ? { ...profile, item } : { ...profile }),
    })
      .then((res) => res.json())
      .then((res) => {
        setIsLoading(false);
        setIsOTPLoading(false)
        // if (true) {
        if (res.status.success) {
          //console.log(res);
          setPopup({ isOpen: false, type: 'smsPopup' });
          setIsSuccess(true);
          setOtpError(null)
          sendEvent("registration", "submit", "SUCCESS")
          // if (getValues().registerType === "device-register") {
          //   createItem()
          // }
          createReciept(finalImgURL, res.meta.bc)
        } else {
          if (res.data.error === "error_wrong_otp") {
            setOtpError("Neteisingas kodas")
          } else if (res.data.error === "error_wrong_otp_email") {
            setOtpError("Neteisingas kodas")
          } else if (res.data.error === "error_device_not_found") {
            setPopup({ isOpen: true, type: 'errorPopup' });
            setRequestError({ description: 'Neteisingas įrenginio kodas. Bandyk iš naujo.', code: res.meta.ts });
          } else if (res.data.error === "error_device_already_registered") {
            setPopup({ isOpen: true, type: 'errorPopup' });
            setRequestError({ description: 'Toks GLO numeris jau yra užregistruotas ', code: res.meta.ts });
          } else if (res.data.error === "error_thalamus_mobile_phone_taken") {
            setPopup({ isOpen: true, type: 'errorPopup' });
            setRequestError({ description: 'Telefono nusmeris jau egzistuoja duomenų bazėje', code: res.meta.ts });
          } else if (res.data.error === "error_thalamus_email_address_taken") {
            setPopup({ isOpen: true, type: 'errorPopup' });
            setRequestError({ description: 'El.paštas jau egzistuoja duomenų bazėje', code: res.meta.ts });
          } else if (res.data.error === "error_thalamus_credential_already_exist") {
            setPopup({ isOpen: true, type: 'errorPopup' });
            setRequestError({ description: 'Įspėjimas! Vartotojo duomenys jau egzistuoja', code: res.meta.ts });
          } else if (res.data.error === "error_bad_value_of_required") {
            setPopup({ isOpen: true, type: 'errorPopup' });
            setRequestError({ description: "Nurodytas elektroninis paštas nėra galiojantis. Prašau suvesti dar kartą", code: res.meta.ts });
          }
          // else if (res.data.error === "error_device_already_registered") {
          //   setPopup({ isOpen: true, type: 'errorPopup' });
          //   setRequestError({ description: 'Toks GLO numeris jau yra užregistruotas ', code: res.meta.ts });
          // } 
          else {
            setPopup({ isOpen: true, type: 'errorPopup' });
            setRequestError({ description: 'Klaida', code: res.meta.ts });
            //sendEvent("registration", "submit", "ERROR")
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setPopup({ isOpen: true, type: 'errorPopup' });
      });
  };

  // const createItem = async () => {
  //   let data = {
  //     "typeCode": "lit_devices",
  //     "principal": getValues().emailAddress,
  //     "itemData": {
  //       "lit_myglo_number": "123456",
  //       "lit_purchase_date": new Date().getTime(),
  //       "lit_brand": getValues().brand,
  //       "lit_model": getValues().model,
  //       "lit_device_colour": getValues().color,
  //       "lit_asset_source": "Hostess",
  //       "tourplanner_id_lit": getValues().tourPlan,
  //       "store_id_lit": "123467",
  //       "hostess_id_lit": getValues().hostessCode,
  //     }
  //   }

  //   let response = await fetch("https://test-bat-rest.thalamuslive.com/bat/api/v4/items?touchpoint=lit_hostess&token=p289jnz6xcu0ukl5rs3sl1qc0g5vmumduo2bwdaajvg5uct84ljduie1bhv312g8", {
  //     method: 'POST',
  //     headers: {
  //       'Authorization': 'Basic ' + Buffer.from(`${getValues().emailAddress}:""`).toString('base64'),
  //       'Content-Type': 'application/json; charset=utf-8',
  //     },
  //     body: JSON.stringify(data),
  //   })
  //   let responseData = await response.json()
  //   if (responseData) {
  //     setIsSuccess(true)
  //   }
  // }

  const verifyPhone = (phoneNumber, email) => {
    // setIsOTPLoading(true)
    const data = {
      "profile": {
        "cellphone": {
          "intCode": inputValue.numberPrefix.substring(1, 3),
          "areaCode": inputValue.numberPrefix.length === 4 ? inputValue.numberPrefix.at(3) : getValues().mobileNumber.at(0),
          "number": inputValue.numberPrefix.length === 4 ? getValues().mobileNumber : getValues().mobileNumber.substring(1)
        }
      }
    }
    fetch(API_URL + "/profile/otp", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        setIsLoading(false);
        // setIsOTPLoading(false)
        if (res.status.success) {

          // setIsSuccess(true);

          console.log(res);
        } else if (!res.status.success && res.data.error === 'error_already_registered') {
          setIsSuccess(true);
          setDevice('error_already_registered');
        } else if (res.data.error === "error_device_not_found") {
          setPopup({ isOpen: true, type: 'errorPopup' });
          setRequestError({ description: 'Neteisingas įrenginio kodas. Bandyk iš naujo.', code: res.meta.ts });
        } else {
          setPopup({ isOpen: true, type: 'errorPopup' });
          setRequestError({ description: 'Klaida', code: res.meta.ts });

        }
      })
      .catch((err) => {
        console.log(err);
        setPopup({ isOpen: true, type: 'errorPopup' });
      });
  };

  const dynamicInputs = () => {
    // console.log(getValues())
    let inputs = null;
    if (getValues().registerType === 'device-register-promo' || getValues().registerType === 'device-register') {
      inputs = (
        <>
          {getValues().registerType === 'device-register-sampling' ?
            <Input
              setValue={setValue}
              getValues={getValues}
              clearErrors={clearErrors}
              type="select"
              register={register({
                required: true
              })}
              name="sampling"
              label="Wybierz Velo"
              placeholder="Wybierz Velo"
              errors={errors}
              event={(value) => { }}
              options={sampling}
            />
            : null
          }
          <Input
            register={register({
              required: true,
            })}
            name="deviceName"
            label="Nazwa urządzenia"
            placeholder="Įrenginio pavadinimas"
            errors={errors}
            event={() => { }}
            value={inputValue.deviceName}
            onChange={handleChange}
          />
          <Input
            setValue={setValue}
            getValues={getValues}
            clearErrors={clearErrors}
            type="select"
            register={register({
              required: true
            })}
            name="brand"
            label="Wybierz urządzenie"
            placeholder="Wybierz urządzenie"
            errors={errors}
            event={(value) => { }}
            value={inputValue.brand}
            disabled={true}
            options={
              [
                {
                  name: "Glo",
                  value: "Glo"
                },
                // {
                //   name: "Vuse",
                //   value: "Vuse"
                // }
              ]
            }
          />
          {getValues().brand === 'Glo' ?
            <>
              <Input
                setValue={setValue}
                getValues={getValues}
                clearErrors={clearErrors}
                type="select"
                register={register({
                  required: true
                })}
                name="model"
                label="Wybierz model Glo"
                placeholder="Pasirinkite Glo modelį"
                errors={errors}
                event={(value) => { setValue("color", "") }}
                options={
                  [
                    {
                      name: "Hyper X2",
                      value: "Hyper X2"
                    },
                    {
                      name: "Hyper X2 Air",
                      value: "Hyper X2 Air"
                    },
                    {
                      name: "Hyper Pro",
                      value: "Hyper Pro",
                    }
                  ]}
              />
              <Input
                setValue={setValue}
                getValues={getValues}
                clearErrors={clearErrors}
                type="select"
                register={register({
                  required: true
                })}
                name="color"
                label="Wybierz kolor"
                placeholder="Pasirinkite spalvą"
                errors={errors}
                event={(value) => { }}
                options={availableColorsGlo}
              />
            </>
            :
            <>
              <Input
                setValue={setValue}
                getValues={getValues}
                clearErrors={clearErrors}
                type="select"
                register={register({
                  required: true
                })}
                name="model"
                label="Wybierz model Vuse"
                placeholder="Wybierz model Vuse"
                errors={errors}
                event={(value) => { }}
                options={
                  [
                    {
                      name: "ePen",
                      value: "ePen"
                    },
                    {
                      name: "ePod 2",
                      value: "ePod 2"
                    }
                  ]}
              />
              <Input
                setValue={setValue}
                getValues={getValues}
                clearErrors={clearErrors}
                type="select"
                register={register({
                  required: true
                })}
                name="color"
                label="Wybierz kolor"
                placeholder="Pasirinkite spalvą"
                errors={errors}
                event={(value) => { }}
                options={availableColors}
              />
            </>
          }
          <Input
            setValue={setValue}
            getValues={getValues}
            clearErrors={clearErrors}
            type="select"
            register={register({
              required: true
            })}
            name="category"
            label="Źródło konsumenta"
            placeholder="Vartotojo šaltinis"
            errors={errors}
            event={(value) => { }}
            options={categories}
          />

          {getValues().category === 'E-Cigarettes' ?
            <Input
              setValue={setValue}
              getValues={getValues}
              clearErrors={clearErrors}
              type="select"
              register={register({
                required: true
              })}
              name="secondaryBrand"
              label="Wybierz e-papierosy"
              placeholder="Pasirinkti el.cigaretės prekės ženklą"
              errors={errors}
              event={(value) => { }}
              options={ecigs}
            />
            : null}
          {getValues().category === 'Traditional cigarettes' ?
            <Input
              setValue={setValue}
              getValues={getValues}
              clearErrors={clearErrors}
              type="select"
              register={register({
                required: true
              })}
              name="secondaryBrand"
              label="Wybierz papierosy tradycyjne"
              placeholder="Pasirinkti tradicinių cigarečių prekės ženklą"
              errors={errors}
              event={(value) => { }}
              options={cigs}
            />
            : null}
          {getValues().category === 'THP' ?
            <Input
              setValue={setValue}
              getValues={getValues}
              clearErrors={clearErrors}
              type="select"
              register={register({
                required: true
              })}
              name="secondaryBrand"
              label="Wybierz papierosy tradycyjne"
              placeholder="Pasirinkti kaitinamojo tabako prekės ženklą"
              errors={errors}
              event={(value) => { }}
              options={thp}
            />
            : null}
          {getValues().registerType !== "device-register-promo" && <Input
            setValue={setValue}
            getValues={getValues}
            clearErrors={clearErrors}
            type="select"
            register={register({
              required: false,
            })}
            name="lit_preferred_voucher"
            label="Voucher"
            placeholder="Kupono tipas"
            errors={errors}
            event={(value) => { }}
            options={[
              {
                name: "Cinema",
                value: "cinema"
              },
              {
                name: "Viada Food",
                value: "viada"
              },
              {
                name: "Viada Carwash",
                value: "viadacarwash"
              },
              {
                name: "CircleK food",
                value: "circlekfood"
              },
              {
                name: "CircleK carwash",
                value: "circlekcarwash"
              },
              {
                name: "Retailer offer",
                value: "retailer offer"
              },
            ]}
          />}
          <Input
            setValue={setValue}
            getValues={getValues}
            clearErrors={clearErrors}
            type="select"
            register={register({
              required: true
            })}
            name="bundle"
            label="Bundle"
            placeholder="Rinkinys"
            errors={errors}
            event={(value) => { }}
            options={bundles}
          />
          {/* <Input
            setValue={setValue}
            getValues={getValues}
            clearErrors={clearErrors}
            type="select"
            register={register({
              required: true
            })}
            name="assetSource"
            label="Źródło urządzenia"
            placeholder="Įrenginio šaltinis"
            errors={errors}
            event={(value) => { }}
            options={assetSources}
            value={inputValue.assetSource}
          /> */}
        </>
      )
    } else if (getValues().registerType === 'register-user') {
      inputs = (
        <>
          <Input
            setValue={setValue}
            getValues={getValues}
            clearErrors={clearErrors}
            type="select"
            register={register({
              required: true
            })}
            name="category"
            label="Źródło konsumenta"
            placeholder="Vartotojo šaltinis"
            errors={errors}
            event={(value) => { }}
            options={categories}
          />

          {getValues().category === 'E-Cigarettes' ?
            <Input
              setValue={setValue}
              getValues={getValues}
              clearErrors={clearErrors}
              type="select"
              register={register({
                required: true
              })}
              name="secondaryBrand"
              label="Wybierz e-papierosy"
              placeholder="Pasirinkite elektronines cigaretes"
              errors={errors}
              event={(value) => { }}
              options={ecigs}
            />
            : null}
          {getValues().category === 'Traditional cigarettes' ?
            <Input
              setValue={setValue}
              getValues={getValues}
              clearErrors={clearErrors}
              type="select"
              register={register({
                required: true
              })}
              name="secondaryBrand"
              label="Wybierz papierosy tradycyjne"
              placeholder="Pasirinkti tradicinių cigarečių prekės ženklą"
              errors={errors}
              event={(value) => { }}
              options={cigs}
            />
            : null}
          {getValues().category === 'THP' ?
            <Input
              setValue={setValue}
              getValues={getValues}
              clearErrors={clearErrors}
              type="select"
              register={register({
                required: true
              })}
              name="secondaryBrand"
              label="Wybierz papierosy tradycyjne"
              placeholder="Pasirinkti kaitinamojo tabako prekės ženklą"
              errors={errors}
              event={(value) => { }}
              options={thp}
            />
            : null}
          <Input
            setValue={setValue}
            getValues={getValues}
            clearErrors={clearErrors}
            type="select"
            register={register({
              required: false,
            })}
            name="lit_preferred_voucher"
            label="Voucher"
            placeholder="Kupono tipas"
            errors={errors}
            event={(value) => { }}
            options={[
              {
                name: "Cinema",
                value: "cinema"
              },
              {
                name: "Viada Food",
                value: "viada"
              },
              {
                name: "Viada Carwash",
                value: "viadacarwash"
              },
              {
                name: "CircleK food",
                value: "circlekfood"
              },
              {
                name: "CircleK carwash",
                value: "circlekcarwash"
              },
              {
                name: "Retailer offer",
                value: "retailer offer"
              },
              {
                name: "Be kupono",
                value: "no-voucher"
              },
            ]}
          />
          {/* <Input
            setValue={setValue}
            getValues={getValues}
            clearErrors={clearErrors}
            type="select"
            register={register({
              required: true
            })}
            name="assetSource"
            label="Źródło urządzenia"
            placeholder="Įrenginio šaltinis"
            errors={errors}
            event={(value) => { }}
            options={assetSources}
          /> */}
        </>
      )
    }

    return inputs;
  }

  useEffect(() => {
    if (getValues().registerType === 'device-register' || getValues().registerType === 'device-register-promo') {
      forceUpdate() // dirty fix
    }
  }, [getValues().registerType])

  console.log(popup.type)
  return (
    <>
      {/* {true ?
        false ? //bypass */}
      {currentAction ?
        isSuccess ?

          <section className="form-section">
            <SuccessPage setSuccess={setIsSuccess} resetForm={resetForm} />
          </section>
          :
          <>
            <Modal close={'hide'} open={popup.isOpen} hidePopup={() => setPopup({ isOpen: false, type: 'smsPopup' })}>
              {popup.type === 'smsPopup' ? (
                <div className="sms-popup">
                  <p>Įveskite gautus patvirtinimo kodus, kuriuos mes tau atsiuntėme el.paštu ir sms žinute: </p>
                  <div className="input-wrapper">
                    {/* <input
                      type="text"
                      required
                      onChange={(e) => setEmailCode(e.target.value)}
                      placeholder="El.pašto kodas"
                      onFocus={() => { }}
                    /> */}
                  </div>
                  <div className="input-wrapper">
                    <input
                      type="text"
                      required
                      onChange={(e) => setSmsCode(e.target.value)}
                      placeholder="SMS kodas"
                      onFocus={() => { }}
                    />
                  </div>
                  {otpError && <p className="error">{otpError}</p>}
                  <p>Negavote kodo?  <a href="/" onClick={(e) => { e.preventDefault(); verifyPhone(getValues().mobileNumber, getValues().emailAddress) }}>Persiųsti</a></p>
                  <div className="buttons btn-margin">
                    <button onClick={() => setPopup({ popup: false, type: popup.type })} className={`btn empty-btn`}>
                      Atšaukti
                    </button>
                    <button
                      onClick={() => { sendForm(formValues, smsCode); }}
                      className={`btn btn-dark ${isOTPLoading ? 'disabled' : ''}`}
                    >
                      {isOTPLoading ? 'Wysyłanie...' : 'Patvirtinti'}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="error-popup">
                  <h2>Oi! Kažkas nutiko.</h2>
                  <h3>{requestError.description}</h3>

                  {requestError.code != '' ? (
                    <h4>
                      Klaidos kodas: <strong>{requestError.code}</strong>
                    </h4>
                  ) : (
                    <h4>Bandyk dar kartą</h4>
                  )}

                  <button onClick={() => setPopup({ popup: false, type: popup.type })} className={`btn btn-dark`}>
                    Grįžti atgal
                  </button>
                </div>
              )}
            </Modal>
            <div className="home-page">
              {initCamera && <div className="camera-output">
                <div className="top-menu">
                  <button onClick={() => setInitCamera(false)}>
                    <img src={BackArrow} alt="" />
                  </button>
                  <button>
                    <img src={Flash} alt="" />
                  </button>
                </div>
                <div className="video-container">
                  <video onCanPlay={() => paintToCanvas()} ref={videoRef} className={imgReady ? "hidden" : ""} />
                  <div ref={stripRef} className={imgReady ? "" : "hidden"}>
                    <img src='' alt='' />
                  </div>
                </div>
                <div className="bottom-menu">
                  <button onClick={() => { setImgReady(false) }}>
                    <img src={Reject} alt="" />
                  </button>
                  <button>
                    <div className="camera-button" onClick={() => { takePhoto() }}></div>
                  </button>
                  <button onClick={() => { setInitCamera(false); setFinalImgURL(stripRef.current.firstChild.href) }}>
                    <img src={Accept} alt="" />
                  </button>
                </div>
              </div>}
              <section className="form-section">
                <h2 className="title">Internetinė forma</h2>
                <form className="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                  <div className="your-data">
                    <div className="container">
                      <div className="input-wrapper">
                        <input
                          ref={register({ required: true })}
                          className="disabled"
                          disabled={true}
                          name="hostessCode"
                          placeholder="Jūsų ID"
                          type="text"
                          value={inputValue.hostessCode}
                        />
                      </div>
                      <div className="input-wrapper">
                        <input
                          ref={register({ required: true })}
                          className="disabled"
                          disabled={true}
                          name="tourPlan"
                          placeholder="Aktyvacijos numeris"
                          type="text"
                          value={inputValue.tourPlan}
                        />
                      </div>
                      <Input
                        register={register({
                          required: true,
                          pattern: { value: /^[\D]{2,}/, message: 'Pole zawiera nieprawidłową wartość' },
                        })}
                        name="firstName"
                        label="Imię"
                        placeholder="Vardas"
                        errors={errors}
                        event={() => { sendEvent("input", "input", "IMIE") }}
                        value={inputValue.firstName}
                        onChange={handleChange}
                      />
                      <Input
                        register={register({
                          required: true,
                          pattern: { value: /^[\D]{2,}/, message: 'Pole zawiera nieprawidłową wartość' },
                        })}
                        name="lastName"
                        label="Nazwisko"
                        placeholder="Pavardė"
                        errors={errors}
                        event={() => { sendEvent("input", "input", "NAZWISKO") }}
                        value={inputValue.lastName}
                        onChange={handleChange}
                      />
                      <Input
                        register={register({
                          required: true,
                          pattern: {
                            value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: 'Neteisingas el.paštas',
                          },
                        })}
                        name="emailAddress"
                        label="Adres e-mail"
                        placeholder="El.pašto adresas"
                        errors={errors}
                        event={() => { sendEvent("input", "input", "EMAIL") }}
                        value={inputValue.email}
                        onChange={handleChange}
                      />
                      <div className="phone-number-wrapper">
                        <Input
                          setValue={setValue}
                          getValues={getValues}
                          clearErrors={clearErrors}
                          type="select"
                          register={register({
                            required: true,
                          })}
                          name="numberPrefix"
                          label=""
                          placeholder=""
                          errors={errors}
                          event={(value) => { }}
                          options={numberPrefixes}
                          onChange={value => setInputValue(prev => ({ ...prev, numberPrefix: value }))}
                          disabled={process.env.REACT_APP_BRANCH !== "dev"}
                        />
                        {console.log(inputValue.numberPrefix)}
                        <Input
                          register={register({
                            required: true,
                            maxLength: 9,
                            pattern: { value: /\d/, message: 'Neteisingas telefono numeris' },
                            // validate: (value) => {
                            //   if (validatePhonePrefix(value)) {
                            //     return true;
                            //   } else {
                            //     return 'Neteisingas telefono numeris';
                            //   }
                            // },
                          })}
                          name="mobileNumber"
                          label="Numer telefonu"
                          placeholder="Telefono numeris"
                          errors={errors}
                          event={() => { sendEvent("input", "input", "TELEFON") }}
                          value={inputValue.mobileNumber}
                          onChange={handleChange}
                        />
                      </div>
                      <Input
                        setValue={setValue}
                        getValues={getValues}
                        clearErrors={clearErrors}
                        type="select"
                        register={register({
                          required: true,
                          pattern: { value: /^[\D]{2,}/, message: 'Pole zawiera nieprawidłową wartość' },
                        })}
                        onChange={
                          () => {
                            setInputValue({
                              ...inputValue,
                              color: '',
                              model: ''
                            });
                          }
                        }
                        name="registerType"
                        label="Wybierz aktywację"
                        placeholder="Pasirinkti aktyvaciją"
                        errors={errors}
                        event={(value) => { }}
                        // value={inputValue.registerType}
                        // disabled={true}
                        options={
                          [
                            {
                              name: "Vartotojo registracija",
                              value: "register-user"
                            },
                            {
                              name: "Vartotojo ir įrenginio registracija", //Rejestracja urządzenia
                              value: "device-register"
                            },
                            {
                              name: "Vartotojo registracija RENGINIUOSE",
                              value: "device-register-promo"
                            },
                          ]
                        }
                      />
                      {dynamicInputs()}
                      { }
                      {/* {getValues().registerType === "device-register" && <>
                        <Input
                          setValue={setValue}
                          getValues={getValues}
                          clearErrors={clearErrors}
                          type="select"
                          register={register({
                            required: true
                          })}
                          name="brand"
                          label="Wybierz urządzenie"
                          placeholder="Wybierz urządzenie"
                          errors={errors}
                          event={(value) => { }}
                          value={inputValue.brand}
                          disabled={true}
                          options={
                            [
                              {
                                name: "Glo",
                                value: "Glo"
                              },
                              // {
                              //   name: "Vuse",
                              //   value: "Vuse"
                              // }
                            ]
                          }
                        />
                        <Input
                          setValue={setValue}
                          getValues={getValues}
                          clearErrors={clearErrors}
                          type="select"
                          register={register({
                            required: true
                          })}
                          name="model"
                          label="Wybierz model Glo"
                          placeholder="Wybierz model Glo"
                          errors={errors}
                          event={(value) => { }}
                          value={inputValue.model}
                          disabled={true}
                          options={
                            [
                              {
                                name: "Hyper+",
                                value: "Hyper+"
                              },
                              // {
                              //   name: "Hyper+ UNIQ",
                              //   value: "Hyper+ UNIQ"
                              // }
                            ]}
                        />
                        <Input
                          setValue={setValue}
                          getValues={getValues}
                          clearErrors={clearErrors}
                          type="select"
                          register={register({
                            required: true
                          })}
                          name="color"
                          label="Wybierz kolor"
                          placeholder="Pasirinkite spalvą"
                          errors={errors}
                          event={(value) => { }}
                          options={availableColorsGlo}
                        /> */}

                      <div className="img-container">
                        <div className="left">
                          <img src={Receipt} alt="" className={imgReady ? "hidden" : "receipt"} />
                          <canvas ref={photoRef} />
                          {/* <div className='img'> */}
                          {/* <div ref={stripRef} className={imgReady ? "" : "hidden"}> */}
                          <img src={finalImgURL} alt='' className={imgReady ? "final-img" : "hidden"} />
                          {/* </div> */}
                          {/* </div> */}
                        </div>
                        <button className="right" onClick={e => { setInitCamera(true); e.preventDefault() }}>
                          <img src={finalImgURL !== "" ? Done : Camera} alt="" />
                          {finalImgURL !== "" ?
                            <p>Pridėtas<br />čekis</p>
                            :
                            <p>Fotografuoti pirkimo<br />čekį</p>
                          }
                        </button>
                      </div>
                      {/* </> */}
                      {/* <p className="disclaimer">
                      Zgody dotyczące przetwarzania danych osobowych przez British American Tobacco Polska
                      Trading sp. z o.o. z siedzibą w Warszawie (dalej jako: "BAT") oraz CHIC spółka z
                      ograniczoną odpowiedzialnością sp.k. z siedzibą w Ostrzeszowie (dalej jako: "CHIC"):
                    </p> */}
                      <div className="consents"></div>
                      <Input
                        register={register({ required: true })}
                        type="checkbox"
                        name="checkbox-1"
                        label={`Patvirtinu, kad esu vyresnis nei 18 metų`}
                        errors={errors}
                        event={() => { sendEvent("checkbox", "click", "ZGODA_WIEK") }}
                      />
                      <Input
                        register={register({ required: true })}
                        type="checkbox"
                        name="checkbox-2"
                        // label={`Wyrażam zgodę na przetwarzanie podanych przeze mnie danych osobowych przez British American Tobacco Polska Trading sp. z o.o. z siedzibą w Warszawie (dalej jako: 'BAT') oraz CHIC spółka z ograniczoną odpowiedzialnością sp.k. z siedzibą w Ostrzeszowie (dalej jako: "CHIC"), jako współadministratorów, w celu przeprowadzenia niepublicznej akcji marketingowej na zasadach określonych w <a href="/pdf/regulamin_niepublicznych_akcji_na_wklady_neo_vuse_velo.pdf">Regulaminie</a> *`}
                        label={"Sutinku, kad BAT LT ir MINERALINIAI VANDENYS, kaip bendri duomenų valdytojai, mano pateiktus asmens duomenis tvarkytų tiesioginės rinkodaros tikslais, įskaitant mano pasirinkimų analizę (profiliavimas), informacijos apie BAT grupės siūlomus produktus, paslaugas, konkursus ir kitas BAT grupės iniciatyvas pateikimą man:"}
                        errors={errors}
                        event={() => { sendEvent("checkbox", "click", "ZGODA_DANE") }}
                      />
                      {/* <p className="disclaimer">
                        Sutinku, kad BAT LT ir MINERALINIAI VANDENYS, kaip bendri duomenų valdytojai, mano pateiktus asmens duomenis tvarkytų tiesioginės rinkodaros tikslais, įskaitant mano pasirinkimų analizę (profiliavimas), informacijos apie BAT grupės siūlomus produktus, paslaugas, konkursus ir kitas BAT grupės iniciatyvas pateikimą man:
                      </p> */}
                      <div className="margin-left-checkboxes">
                        <Input
                          register={register({ required: true })}
                          type="checkbox"
                          name="checkbox-3"
                          label="elektroninėmis ryšio priemonėmis, t. y. el. paštu ir SMS žinutėmis"
                          errors={errors}
                          event={() => { sendEvent("checkbox", "click", "ZGODA_SMSMAIL") }}
                        />
                        <Input
                          register={register({ required: true })}
                          type="checkbox"
                          name="checkbox-4"
                          label="telefonu, įskaitant balso skambučius"
                          errors={errors}
                          event={() => { sendEvent("checkbox", "click", "ZGODA_TEL") }}
                        />
                        {/* <Input
                          register={register({ required: true })}
                          type="checkbox"
                          name="checkbox-5"
                          label="socialinių tinklų (pvz., „Facebook“, „Instagram“ ir pan.) reklamos skiltyse"
                          errors={errors}
                          event={() => { sendEvent("checkbox", "click", "ZGODA_SOCIAL") }}
                        /> */}
                      </div>
                      <Input
                        register={register({ required: false })}
                        type="checkbox"
                        name="checkbox-6"
                        label="Sutinku, kad BAT LT ir MINERALINIAI VANDENYS, kaip bendri duomenų valdytojai, tvarkytų mano asmens duomenis, pateiktus apklausose apie BAT grupės siūlomų produktų naudojimą ir su klientų aptarnavimo programa susijusiose klientų pasitenkinimo apklausose, atliekamose telefonu ir kitomis elektroninėmis ryšio priemonėmis, įskaitant BAT ir MINERALINIAI VANDENYS interneto svetaines, kurių registruotas naudotojas esu.
                        "
                        errors={errors}
                        event={() => { sendEvent("checkbox", "click", "ZGODA_HYPERCARE") }}
                      />
                      <p className="disclaimer">*Privalomas sutikimas</p>
                      <p>
                        Dėmesio: Jūs bet kada galite atšaukti ar pakeisti savo sutikimą. Asmens duomenys tiesioginės rinkodaros ir apklausų vykdymo tikslais tvarkomi kaip nurodyta privatumo politikoje.
                        {/* <strong>daneosobowe@bat.com.pl</strong> */}
                      </p>
                      {/* <p>
                        Współadministratorami danych osobowych, o których mowa w powyższym
                        formularzu są British American Tobacco Polska Trading sp. z o.o. z
                        siedzibą w Warszawie (BAT) oraz CHIC spółka z ograniczoną
                        odpowiedzialnością sp. k. z siedzibą w Ostrzeszowie (CHIC). Twoje dane
                        będą przewarzane wyłącznie w tych celach, na które wyraziłeś/aś swoją
                        zgodę powyżej.
                      </p>
                      <p>
                        Możesz się z nami skontaktować pisząc na adres poczty elektronicznej{' '}
                        <strong>daneosobowe@bat.com.pl</strong> lub listownie na adres British
                        American Tobacco Polska Trading Spółka z o.o., ul. Krakowiaków 48,
                        02-255 Warszawa.
                      </p>
                      <p>
                        W związku z podaniem danych osobowych przysługuje Ci prawo do uzyskania
                        dostępu do treści Twoich danych, prawo ich poprawiania, prawo do ich
                        przenoszenia, prawo do żądania ich usunięcia oraz prawo ograniczenia ich
                        przetwarzania. Jeśli uznasz, że przetwarzamy Twoje dane osobowe
                        niezgodnie z prawem, możesz wnieść skargę do Prezesa Urzędu Ochrony
                        Danych Osobowych.
                      </p>
                      <p>
                        Więcej informacji o ochronie Twoich danych osobowych oraz o zasadach
                        wspólnego nimi administrowania przez BAT oraz CHIC znajdziesz w naszej{' '}
                        <a href="https://www.discoverglo.com/pl/pl/polityka-prywatnosci">
                          Polityce Prywatności
                        </a>
                      </p> */}
                      <div ref={dropdownRef} className="disclaimer-dropdown-wrapper">
                        {/*<button
                          type="button"
                          className="btn btn-text"
                          onClick={() => { setIsDropdown(!isDropdown); sendEvent("button", "click", "WIĘCEJ")}}
                        >
                          <span>Więcej</span>
                          <img src="/assets/img/sort-down.svg" />
                        </button>*/}
                        {/*<div className={`dropdown-container ${isDropdown ? 'show' : ''}`}>
                          <img
                            onClick={() => setIsDropdown(false)}
                            className="cancel"
                            src="/assets/img/cancel.svg"
                          />
                          <p>
                            Pamiętaj, że zawsze możesz wycofać powyższe zgody na przetwarzanie
                            Twoich danych osobowych. Wycofanie zgody nie będzie miało jednak wpływu
                            na zgodność z prawem naszych działań podjętych jeszcze przed jej
                            wycofaniem. Aby wycofać swoją zgodę skontaktuj się z nami pod adresem:
                            <strong>daneosobowe@bat.com.pl</strong>
                          </p>
                          <p>
                            Współadministratorami danych osobowych, o których mowa w powyższym
                            formularzu są British American Tobacco Polska Trading sp. z o.o. z
                            siedzibą w Warszawie (BAT) oraz CHIC spółka z ograniczoną
                            odpowiedzialnością sp. k. z siedzibą w Ostrzeszowie (CHIC). Twoje dane
                            będą przewarzane wyłącznie w tych celach, na które wyraziłeś/aś swoją
                            zgodę powyżej.
                          </p>
                          <p>
                            Możesz się z nami skontaktować pisząc na adres poczty elektronicznej{' '}
                            <strong>daneosobowe@bat.com.pl</strong> lub listownie na adres British
                            American Tobacco Polska Trading Spółka z o.o., ul. Krakowiaków 48,
                            02-255 Warszawa.
                          </p>
                          <p>
                            W związku z podaniem danych osobowych przysługuje Ci prawo do uzyskania
                            dostępu do treści Twoich danych, prawo ich poprawiania, prawo do ich
                            przenoszenia, prawo do żądania ich usunięcia oraz prawo ograniczenia ich
                            przetwarzania. Jeśli uznasz, że przetwarzamy Twoje dane osobowe
                            niezgodnie z prawem, możesz wnieść skargę do Prezesa Urzędu Ochrony
                            Danych Osobowych.
                          </p>
                          <p>
                            Więcej informacji o ochronie Twoich danych osobowych oraz o zasadach
                            wspólnego nimi administrowania przez BAT oraz CHIC znajdziesz w naszej{' '}
                            <a href="https://www.discoverglo.com/pl/pl/polityka-prywatnosci">
                              Polityce Prywatności
                            </a>
                          </p>
                        </div>*/}
                      </div>
                      {/* <p className="disclaimer">
                        Współadministratorami danych osobowych, o których mowa w powyższym formularzu są
                        British American Tobacco Polska Trading sp. z o.o. z siedzibą w Warszawie (BAT)
                        oraz CHIC spółka z ograniczoną odpowiedzialnością sp. k. z siedzibą w
                        Ostrzeszowie (CHIC). Twoje dane będą przewarzane wyłącznie w tych celach, na
                        które wyraziłeś/aś swoją zgodę powyżej.
                      </p>
                      <p className="disclaimer">
                        Możesz się z nami skontaktować pisząc na adres poczty elektronicznej
                        daneosobowe@bat.com.pl lub listownie na adres British American Tobacco Polska
                        Trading Spółka z o.o., ul. Krakowiaków 48, 02-255 Warszawa.
                      </p>
                      <p className="disclaimer">
                        W związku z podaniem danych osobowych przysługuje Ci prawo do uzyskania dostępu
                        do treści Twoich danych, prawo ich poprawiania, prawo do ich przenoszenia, prawo
                        do żądania ich usunięcia oraz prawo ograniczenia ich przetwarzania. Jeśli
                        uznasz, że przetwarzamy Twoje dane osobowe niezgodnie z prawem, możesz wnieść
                        skargę do Prezesa Urzędu Ochrony Danych Osobowych.
                      </p>
                      <p className="disclaimer">
                        W związku z podaniem danych osobowych przysługuje Ci prawo do uzyskania dostępu
                        do treści Twoich danych, prawo ich poprawiania, prawo do ich przenoszenia, prawo
                        do żądania ich usunięcia oraz prawo ograniczenia ich przetwarzania. Jeśli
                        uznasz, że przetwarzamy Twoje dane osobowe niezgodnie z prawem, możesz wnieść
                        skargę do Prezesa Urzędu Ochrony Danych Osobowych.
                      </p>
                      <p className="disclaimer">
                        Więcej informacji o ochronie Twoich danych osobowych oraz o zasadach wspólnego
                        nimi administrowania przez BAT oraz CHIC znajdziesz w naszej{' '}
                        <a href="https://www.discoverglo.com/pl/pl/polityka-prywatnosci">
                          Polityce Prywatności
                        </a>
                      </p> */}

                      {/*<button
                      type="button"
                      className="btn btn-dark show-consents"
                      onClick={() => { setIsConsentSectionCollapsed(!isConsentSectionCollapsed); sendEvent("button", "click", "ZGODY") }}
                    >
                      {!isConsentSectionCollapsed ? 'Pokaż zgody' : 'Ukryj zgody'}
                    </button>*/}
                    </div>
                  </div>
                  <button className={`btn ${isLoading ? 'disabled' : ''}`} type="submit">
                    {isLoading ? 'Proszę czekać...' : 'Patvirtinti el.paštą ir telefono numerį'}
                  </button>
                </form>
              </section>
            </div>
          </>
        :
        <div className="home-page">
          <section className="form-section">
            <h2 className="title">Internetinė forma</h2>
            <p>Aktyvių veiksmų nėra</p>
          </section>
        </div>
      }
    </>
  );
};


export default Webform;